import React from "react"
import Layout from "../../components/layout"
import CapabilitiesHeroSection from "../../components/capabilitiesherosection"
import AuditButtons from "../../components/auditbuttons"
import SingleButton from "../../components/singlebutton"
import { Helmet } from "react-helmet"
import Audits from "../../components/audits"
import Arrow from "../../components/arrow"

import capabilityicon from "../../images/capability-digital-media-2.png"

import digitalmediaplanning from "../../images/digital-media-planning.png"
import search from "../../images/search.png"
import programmaticmedia from "../../images/programmatic-media.png"
import displayandretargeting from "../../images/display-and-retargeting.png"
import socialads from "../../images/social-ads.png"
import videoads from "../../images/video-ads.png"
import sponsoredcontent from "../../images/sponsored-content.png"
import mediaattributionmodelling from "../../images/media-attribution-modelling.png"


import tv from "../../images/tv.png"
import print from "../../images/print.png"
import outdoor from "../../images/outdoor.png"
import radio from "../../images/radio.png"
import cinema from "../../images/cinema.png"


import rowtwoheroleft from "../../images/row-two-hero-left.jpg"
import rowtwoheroright from "../../images/row-two-hero-right.jpg"
import rowfivehero from "../../images/row-five-hero.jpg"
import rowsixhero1 from "../../images/row-six-hero-1.png"
import rowsixhero2 from "../../images/row-six-hero-2.png"
import rowsixhero3 from "../../images/row-six-hero-3.png"
import rowsixhero4 from "../../images/row-six-hero-4.png"
import rowsevenhero from "../../images/row-seven-hero.png"
import roweighthero from "../../images/row-eight-hero.jpg"
import rowninehero from "../../images/row-nine-hero.jpg"
import rowtenhero from "../../images/row-ten-hero.png"

import "../../scss/capabilitypage.scss"

const Digitalmedia = ({ data }) => {
  const metadescription =
    "Next&Co's media buying services provide audience led and data informed insights for your brands activity with measurable value, visit our site or call 1300 191 950"
  const metatitle =
    "Media buying Agency Melbourne, SEM Agency Melbourne - Next&Co"
  const metaurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription} />
        <meta name="title" content={metatitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaurl} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta property="og:image" content="facebook.png" />
        <meta property="twitter:url" content={metaurl} />
        <meta property="twitter:title" content={metatitle} />
        <meta property="twitter:description" content={metadescription} />
        <meta property="twitter:image" content="twitter.png" />
      </Helmet>
      <Arrow />
      <div className="capability-page-general digital-media-page">
        <Layout>
          <CapabilitiesHeroSection
            heading="Media Buying"
            description="Media Buying – Audience led, data-informed media buying"
            icon={capabilityicon}
            imagedesktop={data.desktop.childImageSharp.fluid}
            imagemobile={data.mobile.childImageSharp.fluid}
          />
          <section id="row-three">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container row">
                  <div className="col-one">
                    <h4 className="col-onef-heading">What we do</h4>
                  </div>
                  <div className="col-one">
                    <div className="list-items">
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={digitalmediaplanning} />
                        </span>
                        <div className="content">
                          <h3>
                            Media Buying <br />
                            planning
                          </h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img
                            alt=""
                            src={search}
                            style={{ maxWidth: `37px` }}
                          />
                        </span>
                        <div className="content">
                          <h3>Search</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img
                            alt=""
                            src={programmaticmedia}
                            style={{ maxWidth: `35px` }}
                          />
                        </span>
                        <div className="content">
                          <h3>Programmatic media</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img
                            alt=""
                            src={displayandretargeting}
                            style={{ maxWidth: `50px` }}
                          />
                        </span>
                        <div className="content">
                          <h3>Display and retargeting</h3>
                        </div>
                      </div>
                      
                      <div className="list-item">
                        <span className="icon">
                          <img
                            alt=""
                            src={tv}
                            style={{ maxWidth: `50px` }}
                          />
                        </span>
                        <div className="content">
                          <h3>TV</h3>
                        </div>
                      </div>
                      
                      <div className="list-item">
                        <span className="icon">
                          <img
                            alt=""
                            src={print}
                            style={{ maxWidth: `50px` }}
                          />
                        </span>
                        <div className="content">
                          <h3>Print</h3>
                        </div>
                      </div>
                      
                      <div className="list-item">
                        <span className="icon">
                          <img
                            alt=""
                            src={outdoor}
                            style={{ maxWidth: `50px` }}
                          />
                        </span>
                        <div className="content">
                          <h3>Outdoor</h3>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div className="col-one">
                    <div className="list-items">
                      <div className="list-item">
                        <span className="icon">
                          <img
                            alt=""
                            src={socialads}
                            style={{ maxWidth: `35px` }}
                          />
                        </span>
                        <div className="content">
                          <h3>Social ads</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img
                            alt=""
                            src={videoads}
                            style={{ maxWidth: `50px` }}
                          />
                        </span>
                        <div className="content">
                          <h3>Video ads</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img
                            alt=""
                            src={sponsoredcontent}
                            style={{ maxWidth: `45px` }}
                          />
                        </span>
                        <div className="content">
                          <h3>Sponsored content</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img
                            alt=""
                            src={mediaattributionmodelling}
                            style={{ maxWidth: `45px` }}
                          />
                        </span>
                        <div className="content">
                          <h3>Media attribution modelling</h3>
                        </div>
                      </div>
                      
                      <div className="list-item">
                        <span className="icon">
                          <img
                            alt=""
                            src={radio}
                            style={{ maxWidth: `50px` }}
                          />
                        </span>
                        <div className="content">
                          <h3>Radio</h3>
                        </div>
                      </div>
                      
                      <div className="list-item">
                        <span className="icon">
                          <img
                            alt=""
                            src={cinema}
                            style={{ maxWidth: `50px` }}
                          />
                        </span>
                        <div className="content">
                          <h3>Cinema</h3>
                        </div>
                      </div>
                      
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <AuditButtons
            title1="Let’s talk about your media."
            title2="Assess your current performance."
            linktitle1="Get in touch"
            link1="#"
            linktitle2="Media Buying Audit"
            link2="/digital-media-audit"
          />
          <section id="row-two">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <h4>Audience-led media buying</h4>
                  <p>
                    Curious, connected and well–researched. The modern customer
                    has access to multiple channels to take their journey from
                    initial research all the way to becoming a potential
                    advocate for your brand. Their personalities, attitudes,
                    beliefs, values and experiences shape their interactions
                    with your brand along their journey. These factors all make
                    each journey unique from the next customer.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <img alt="" src={rowtwoheroleft} />
                </div>
                <div className="col">
                  <img alt="" src={rowtwoheroright} />
                </div>
              </div>
            </div>
          </section>
          <section id="row-five">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <h4>Media Buying matched to the customer journey</h4>
                </div>
                <div className="row full-width-image">
                  <div className="col">
                    <h5>We need to understand the customer journey…</h5>
                    <img alt="" src={rowfivehero} />
                  </div>
                </div>
                <div className="inner-container">
                  <div className="small-inner-container">
                    <p>
                      Every step along the way counts towards that destination.
                      Whether it’s an initial engagement, trial sign-up or final
                      purchase – the customer requires a different experience at
                      each step, personalised for their unique journey.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-six">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="row">
                    <div className="col">
                      <h4>Our media buying strategic framework</h4>
                    </div>
                    <div className="col col-mobile">
                      <div className="scroll">
                        <div className="row">
                          <img
                            className="show-desktop"
                            alt=""
                            src={rowsixhero1}
                          />
                          <img
                            className="show-desktop"
                            alt=""
                            src={rowsixhero2}
                          />
                        </div>
                        <div className="row">
                          <img
                            className="show-desktop"
                            alt=""
                            src={rowsixhero3}
                          />
                          <img
                            className="show-desktop"
                            alt=""
                            src={rowsixhero4}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <AuditButtons
            title1="Let’s talk about your media."
            title2="Assess your current performance."
            linktitle1="Get in touch"
            link1="#"
            linktitle2="Media Buying Audit"
            link2="/digital-media-audit"
          />
          <section id="row-seven">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col">
                    <h4>Human ingenuity mixed with AI precision</h4>
                    <p>
                      We combine the best of both worlds: the in–depth skills,
                      knowledge and expertise of our team along with AI–driven
                      automation to drive optimisation. Our team will align the
                      media strategy and planning elements to meet your
                      commercial targets, while our AI–based technologies will
                      run micro optimisations in the background to ensure that
                      you meet your desired campaign goals.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <img alt="" src={rowsevenhero} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-eight">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="col">
                  <div className="row">
                    <img alt="" src={roweighthero} />
                  </div>
                  <div className="col">
                    <h4>Understanding the impact of each channel</h4>
                    <p>
                      We aim to understand the overall purchase or conversion.
                      Once enough data has been gathered, a custom position &
                      time weighted attribution model will allow us to
                      understand how each channel contributes to the overall
                      purchase (or other desired outcome) within the customer
                      journey.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <AuditButtons
            title1="Let’s talk about your media."
            title2="Assess your current performance."
            linktitle1="Get in touch"
            link1="#"
            linktitle2="Media Buying audit"
            link2="/digital-media-audit"
          />
          <section id="row-nine">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="col">
                  <div className="col">
                    <h4>Media Buying integration</h4>
                    <p>
                      We recognise that digital media does not live in a vacuum.
                      Our desire is to better understand how this channel better
                      sits as a wider part of the customer’s brand ecosystem. We
                      aim to offer an integrated media buying experience to get
                      the most value out of all the elements of the brand
                      ecosystem.
                    </p>
                  </div>
                  <div className="row">
                    <img alt="" src={rowninehero} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-ten">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="small-inner-container">
                    <div className="col">
                      <h4>Media Buying that gets better day by day...</h4>
                      <p>
                        We believe in rhythmic, continuous improvement –
                        consistently moving resources and budget from
                        non-converting activity to converting activity. With
                        this system of continuous improvement, we help your
                        media activity to become better day by day, every day.
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <img alt="" src={rowtenhero} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-four">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <h3>
                      Many of Australia's leading businesses partner with us,
                      you should too.
                    </h3>
                    <SingleButton buttonText="Get in touch" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Audits />
        </Layout>
      </div>
    </>
  )
}

export default Digitalmedia

export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "capabilities-desktop-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    mobile: file(relativePath: { eq: "capabilities-mobile-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
